import header from './header';
import footer from './footer';
import dangerousHtml from './dangerous-html';
import customSelect from './custom-select';
import replaceFontAwesome from './replace-font-awesome';
import theme from './theme';

const customCSS = `
  ::-webkit-inner-spin-button {
    display: none;
  }

  ::-webkit-calendar-picker-indicator {
    display: none;
  }

    ::-webkit-scrollbar {
      appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: ${theme.SCROLL_BAR_WIDTH};
    }

    ::-webkit-scrollbar:horizontal {
      height: ${theme.SCROLL_BAR_WIDTH};
    }

    ::-webkit-scrollbar-track {
      background-color: ${theme.SCROLL_BAR_BACKGROUND};
      border-left: ${theme.SCROLL_BAR_BORDER_WIDTH} solid ${theme.SCROLL_BAR_BORDER_COLOR};
      border-right: ${theme.SCROLL_BAR_BORDER_WIDTH} solid ${theme.SCROLL_BAR_BORDER_COLOR};
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.SCROLL_BAR_COLOR};
      border-left: ${theme.SCROLL_BAR_BORDER_WIDTH} solid ${theme.SCROLL_BAR_BORDER_COLOR};
      border-right: ${theme.SCROLL_BAR_BORDER_WIDTH} solid ${theme.SCROLL_BAR_BORDER_COLOR};
      border-radius: ${theme.SCROLL_BAR_BORDER_RADIUS};
    }

  dialog {
    color: inherit;
  }

  body {
    overflow-x: hidden;
  }

  body.drawer-open {
    overflow-y: hidden;
  }

  @media all {
    select::-ms-expand {
      display: none;
    }
  }

  ${header}
  ${footer}
  ${dangerousHtml}
  ${customSelect}
  ${replaceFontAwesome}
`;

export default customCSS;
